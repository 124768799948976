import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import CrewEquipmentForm from "./CrewEquipmentForm";

export default function CreateCrewModal() {
  const showCreateCrewModal = useUIStore((state) => state.showCreateCrewModal);
  const updateCrew = useCrewStore((state) => state.updateCrew);
  const createCrew = useCrewStore((state) => state.createCrew);
  const activeCrewToEdit = useCrewStore((state) => state.activeCrewToEdit);

  const [showError, setShowError] = useState(false);

  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showCreateCrewModal}
        contentLabel="Minimal Modal Example"
        style={{
          overlay: {
            // backgroundColor: "papayawhip",
            zIndex: 999999999,
            height: "50%",
            width: "50%",
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">{activeCrewToEdit.name ? `Editing ${activeCrewToEdit.name}` : "Create New Crew"}</h4>
            <button
              onClick={() => {
                useUIStore.setState({ showCreateCrewModal: !showCreateCrewModal });
                useCrewStore.setState({ activeCrewToEdit: {} });
              }}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content has-double-pane">
            <Formik
              enableReinitialize={true}
              initialValues={activeCrewToEdit.name ? { name: activeCrewToEdit.name, id: activeCrewToEdit.id, manure_mode: activeCrewToEdit.manure_mode } : { name: "", manure_mode: false }}
              validate={(values) => {
                const errors = {};
                if (!values.name || values.name === "") {
                  errors.name = "Required";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                // Create vs. Edit logic
                if (activeCrewToEdit.name) {
                  const success = await updateCrew(values);
                  if (success) {
                    useUIStore.setState({ showCreateCrewModal: false });
                  } else {
                    setShowError(true);
                  }
                } else {
                  const success = await createCrew(values);
                  if (success) {
                    useUIStore.setState({ showCreateCrewModal: false });
                  } else {
                    setShowError(true);
                  }
                }
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <label className="formik-label" htmlFor="note">
                          Crew Name
                        </label>
                        <Field name="name" label="name" type="text" />
                        <ErrorMessage name="name" component="div" className="formik-error" />

                        {showError && (
                          <div>
                            <p className="red">`There was an error ${activeCrewToEdit.name ? "updating" : "creating"} this crew.`</p>
                          </div>
                        )}
                      </div>
                      <div className="col-6 mt-4">
                        <Field name="manure_mode" type="checkbox" id="manure_mode" />
                        <label className="formik-label ms-1" htmlFor="manure_mode">
                          Manure Mode
                        </label>
                        <ErrorMessage name="manure_mode" component="div" className="formik-error" />
                      </div>
                    </div>
                  </div>

                  <div className="action-stuck-bottom">
                    <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                      {activeCrewToEdit.name ? "Update" : "Create"} Crew
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
