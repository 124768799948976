import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";
import useEquipmentStore from "../stores/equipmentStore";

export default function CrewEquipmentform() {
  const customerEquipment = useEquipmentStore((state) => state.customerEquipment);
  const crews = useCrewStore((state) => state.crews);
  const deleteCrew = useCrewStore((state) => state.deleteCrew);
  const updateCrewEquipment = useCrewStore((state) => state.updateCrewEquipment);
  const loading = useCrewStore((state) => state.loading);

  const [selectedCrew, setSelectedCrew] = useState(null);
  const [checkedValues, setCheckedValues] = useState(null);

  useEffect(() => {
    if (selectedCrew) {
      setCheckedValues(getInitialEquipmentSelection());
    }
  }, [selectedCrew, customerEquipment]);

  const setActiveCrewToEditAndOpenModal = (crew) => {
    useCrewStore.setState({ activeCrewToEdit: { id: crew.id, name: crew.name, manure_mode: crew.manure_mode } });
    useUIStore.setState({ showCreateCrewModal: true });
  };

  const renderCrews = () => {
    return crews.map((crew) => (
      <Fragment key={crew.id}>
        <div
          className={crew.id === selectedCrew ? "mb-3 div-as-button full-width selected" : "mb-3 div-as-button full-width"}
          style={{ width: "80%" }}
          onClick={() => setSelectedCrew(crew.id)}>
          {crew.name}
          <button
            className="btn-sm"
            onClick={() => window.confirm("Are you sure you want to delete this crew?", () => deleteCrew(crew.id, setSelectedCrew))}
            style={{ float: "right", marginLeft: "1em" }}>
            Delete
          </button>
          <button className="btn-sm" onClick={() => setActiveCrewToEditAndOpenModal(crew)} style={{ float: "right" }}>
            Edit
          </button>
        </div>
      </Fragment>
    ));
  };

  const getInitialEquipmentSelection = () => {
    return customerEquipment.map((e) => {
      if (e.crew_id === selectedCrew) {
        return e.id.toString();
      }
    });
  };

  const renderCustomerEquipmentLabel = (equipment) => {
    return equipment.crew_name ? equipment.name + " (" + equipment.crew_name + ")" : equipment.name;
  };

  const renderForm = () => {
    return (
      <div className="double-pane-scrollers">
        <div className="pane-1">
          <h4 className="mb-2">Select Crew</h4>
          {renderCrews()}
        </div>

        <div className="pane-2">
          {selectedCrew && (
            <>
              <h4 className="mb-2">Assign Equipment</h4>
              <Formik
                enableReinitialize={true}
                initialValues={{ equipment_ids: checkedValues }}
                onSubmit={async (values) => {
                  const updated = await updateCrewEquipment(selectedCrew, values);
                }}>
                {({ isSubmitting }) => (
                  <Form>
                    <div role="group" aria-labelledby="checkbox-group">
                      {customerEquipment.map((e) => (
                        <div className="check-as-button mb-3" key={e.id}>
                          <Field type="checkbox" name="equipment_ids" id={`assign-equipment-${e.id}`} value={e.id.toString()} />
                          <label htmlFor={`assign-equipment-${e.id}`}>{renderCustomerEquipmentLabel(e)}</label>
                        </div>
                      ))}
                    </div>
                    <div className="action-stuck-bottom">
                      <button type="submit" disabled={loading} className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </div>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
