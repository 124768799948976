import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useSilageTicketStore from "../../stores/silageTicketStore";
import useDumpLocationStore from "../../stores/dumpLocationStore";
import useEquipnmentStore from "../../stores/equipmentStore";
import useFieldStore from "../../stores/fieldStore";
import useCrewStore from "../../stores/crewStore";
import useAppStore from "../../stores/appStore";
import MonitorLoadSizeChange from "../MonitorLoadSizeChange";
import {
  getCropType,
  getTareWeight,
  setTareWeight,
  getMileage,
  getOtherLoadSizeUsed,
  getLastField,
  getLastDumpLocation,
  getCutNumber,
  setCutNumber,
  getPitNumber,
  setPitNumber,
  getScaleWeight,
} from "../../lib/storage";
import FarmFieldSelect from "./FarmFieldSelect";

export default function SilageTruckForm() {
  const dumpLocations = useDumpLocationStore((state) => state.dumpLocations);
  const customerChoppers = useEquipnmentStore((state) => state.customerChoppers);
  const fields = useFieldStore((state) => state.crewFields);
  const createSilageTicket = useSilageTicketStore((state) => state.createSilageTicket);
  const loading = useSilageTicketStore((state) => state.loading);
  const crew = useCrewStore((state) => state.crew);
  const application_types = useAppStore((state) => state.application_types);

  const [showGrossAndTare, setShowGrossAndTare] = useState(false);
  const [netWeight, setNetWeight] = useState(0);
  const [needDumpLocation, setNeedDumpLocation] = useState(false);

  const handleSubmit = (values, setSubmitting) => {
    setCutNumber(values.cut_number);
    setPitNumber(values.pit_number);
    if (values.load_size !== "other") {
      createSilageTicket({ ...values, gross_weight: null, tare_weight: null, net_weight: null });
    } else {
      createSilageTicket({ ...values, net_weight: netWeight });
    }
    // createSilageTicket({ ...values, net_weight: netWeight });
    setSubmitting(false);
  };

  const setToEmpty = (values, fieldName, setFieldCallback) => {
    if (values[fieldName] === 0) {
      setFieldCallback(fieldName, "");
    }
  };

  const setToZero = (values, fieldName, setFieldCallback) => {
    if (values[fieldName] === "") {
      setFieldCallback(fieldName, 0);
    } else {
      setFieldCallback(fieldName, values[fieldName].toFixed(2));
    }
  };

  const renderDumpLocations = () => {
    return dumpLocations.map((dl) => (
      <option key={dl.id} value={dl.id}>
        {dl.name}
      </option>
    ));
  };

  const renderChoppers = () => {
    return customerChoppers.map((chopper) => (
      <option key={chopper.id} value={chopper.id}>
        {chopper.name}
      </option>
    ));
  };

  const storeTareWeight = (tareWeight) => {
    setTareWeight(tareWeight);
  };

  const getFieldId = () => {
    if (getLastField() != null) {
      return getLastField();
    } else {
      try {
        return fields[0].id;
      } catch (e) {
        return "";
      }
    }
  };

  const calculateNetWeight = (gross, tare) => {
    if (!isNaN(gross) && !isNaN(tare)) {
      setNetWeight(Math.ceil(gross - tare));
      return Math.ceil(gross - tare);
    } else {
      return "";
    }
  };

  const calculateNetWeightTons = (gross, tare) => {
    if (!isNaN(gross) && !isNaN(tare)) {
      return `${((gross - tare) * 0.0005).toFixed(2)} tons`;
    } else {
      return "";
    }
  };

  const calculateScaleMultiplier = (dumpLocationId, setValue, form_value) => {
    const scaleWeight = getScaleWeight();
    const dumpLocation = dumpLocations.find((location) => location.id === parseInt(dumpLocationId));
    if (!!dumpLocation && !!dumpLocation.scale_multiplier && parseFloat(dumpLocation.scale_multiplier) > 0) {
      setValue(form_value, Math.round(scaleWeight * dumpLocation.scale_multiplier));
      if (form_value === "tare_weight") {
        setTareWeight(Math.round(scaleWeight * dumpLocation.scale_multiplier));
      }
      setNeedDumpLocation(false);
    } else if (!!dumpLocation) {
      setValue(form_value, Math.round(scaleWeight));
      if (form_value === "tare_weight") {
        setTareWeight(Math.round(scaleWeight));
      }
      setNeedDumpLocation(false);
    } else {
      setNeedDumpLocation(true);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          number: "",
          cut_number: getCutNumber() || "",
          pit_number: getPitNumber() || "",
          crop_type: crew.manure_mode ? "" : getCropType() || "",
          field_id: getFieldId() || "",
          from_chopper_id: "",
          dump_location_id: getLastDumpLocation() || "",
          miles: getMileage() || 0,
          gross_weight: 0,
          tare_weight: getTareWeight() || 0,
          net_weight: 0,
          load_size: getOtherLoadSizeUsed() === "true" ? "other" : "",
        }}
        validate={(values) => {
          const errors = {};
          // if (!values.cut_number || values.cut_number === "") {
          //   errors.cut_number = "Required";
          // }
          if (!!values.pit_number && (values.pit_number === "" || parseInt(values.pit_number) < 1 || parseInt(values.pit_number) > 10)) {
            errors.pit_number = "Must be 1 - 10";
          }
          if (!values.crop_type || values.crop_type === "") {
            errors.crop_type = "Required";
          }
          if (!values.field_id || values.field_id === "") {
            errors.field_id = "Required";
          }
          // if (!values.from_chopper_id || values.from_chopper_id === "") {
          //   errors.from_chopper_id = "Required";
          // }
          if (!values.dump_location_id || values.dump_location_id === "") {
            errors.dump_location_id = "Required";
          }
          if (!values.miles || values.miles === "") {
            errors.miles = "Required";
          }
          if (!values.load_size || values.load_size === "") {
            errors.load_size = "Required";
          }

          // Gross weight range 9999 - 195,000
          if (
            values.load_size === "other" &&
            (!values.gross_weight ||
              values.gross_weight === "" ||
              parseInt(values.gross_weight) < 9999 ||
              parseInt(values.gross_weight) > 195000)
          ) {
            errors.gross_weight = "9,999 - 195,000";
          }

          // Tare weight must be <= gross weight
          if (
            values.load_size === "other" &&
            (!values.tare_weight || values.tare_weight === "" || parseInt(values.tare_weight) > parseInt(values.gross_weight))
          ) {
            errors.tare_weight = "Must be <= gross weight";
          }

          // Net weight <= 100,000
          if (values.load_size === "other" && netWeight > 130000) {
            errors.net_weight = "Must be <= 130,000";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          storeTareWeight(values.tare_weight);
          handleSubmit(values, setSubmitting);
        }}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="container">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="number">
                    Ticket Number
                  </label>
                  <Field name="number" label="number" type="text" />
                  <ErrorMessage name="number" component="div" className="formik-error" />
                </div>
                {crew.manure_mode ? (
                  <div className="col">
                    <label className="formik-label" htmlFor="fertilizer_id">
                      Application Type
                    </label>
                    <Field name="fertilizer_id" as="select">
                      <option value="">Select an application type</option>
                      {application_types.map((application) => (
                        <option key={application.id} value={application.id}>
                          {application.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="fertilizer_id" component="div" className="formik-error" />
                  </div>
                ) : (
                  <div className="col">
                    <label className="formik-label" htmlFor="crop_type">
                      Crop Type
                    </label>
                    <Field name="crop_type" as="select">
                      <option value="">Select a crop type</option>
                      <option value="alfalfa">Alfalfa</option>
                      <option value="barley">Barley</option>
                      <option value="brome_grass">Brome Grass</option>
                      <option value="buck_wheat">Buck Wheat</option>
                      <option value="canary_seed">Canary Seed</option>
                      <option value="canola">Canola</option>
                      <option value="corn">Corn</option>
                      <option value="earlage">Earlage</option>
                      <option value="flax">Flax</option>
                      <option value="lentil">Lentils</option>
                      <option value="linola">Linola</option>
                      <option value="manure">Manure</option>
                      <option value="millet">Millet</option>
                      <option value="mustard">Mustard</option>
                      <option value="oats">Oats</option>
                      <option value="peas">Peas</option>
                      <option value="potatoes">Potatoes</option>
                      <option value="rye">Rye</option>
                      <option value="silage">Silage</option>
                      <option value="sorghum">Sorghum</option>
                      <option value="soybeans">Soybeans</option>
                      <option value="straw">Straw</option>
                      <option value="sunflowers">Sunflowers</option>
                      <option value="triticale">Triticale</option>
                      <option value="wheat">Wheat</option>
                    </Field>
                    <ErrorMessage name="crop_type" component="div" className="formik-error" />
                  </div>
                )}
              </div>
              <div className="row">
                <FarmFieldSelect />

                <div className="col">
                  <label className="formik-label" htmlFor="dump_location_id">
                    Dump Location
                  </label>
                  <Field name="dump_location_id" label="dump_location" as="select">
                    <option value="">Select a dump location</option>
                    {renderDumpLocations()}
                  </Field>
                  <ErrorMessage name="dump_location_id" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="cut_number">
                    Cut Number
                  </label>
                  <Field name="cut_number" as="select">
                    <option value="">Select cut number</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Field>
                  <ErrorMessage name="cut_number" component="div" className="formik-error" />
                </div>

                <div className="col">
                  <label className="formik-label" htmlFor="pit_number">
                    Pit Number
                  </label>
                  <Field name="pit_number" type="number" />
                  <ErrorMessage name="pit_number" component="div" className="formik-error" />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="from_chopper_id">
                    From Chopper
                  </label>
                  <Field name="from_chopper_id" label="from_chopper" as="select">
                    <option value="">Select a chopper</option>
                    {renderChoppers()}
                  </Field>
                  <ErrorMessage name="from_chopper_id" component="div" className="formik-error" />
                </div>

                <div className="col">
                  <label className="formik-label" htmlFor="miles">
                    Miles
                  </label>
                  <Field
                    name="miles"
                    label="miles"
                    type="number"
                    onClick={() => setToEmpty(values, "miles", setFieldValue)}
                    onBlur={() => setToZero(values, "miles", setFieldValue)}
                  />
                  <ErrorMessage name="miles" component="div" className="formik-error" />
                </div>
              </div>

              {showGrossAndTare && (
                <>
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <div className="col">
                      <label className="formik-label" htmlFor="gross_weight">
                        Gross Weight
                      </label>
                      <Field
                        name="gross_weight"
                        label="gross_weight"
                        type="number"
                        step="0.01"
                        onClick={() => setToEmpty(values, "gross_weight", setFieldValue)}
                        onBlur={() => setToZero(values, "gross_weight", setFieldValue)}
                      />
                      <ErrorMessage name="gross_weight" component="div" className="formik-error" />
                    </div>
                    <div className="col">
                      <label className="formik-label" htmlFor="tare_weight">
                        Tare Weight
                      </label>
                      <Field
                        name="tare_weight"
                        label="tare_weight"
                        type="number"
                        onClick={() => setToEmpty(values, "tare_weight", setFieldValue)}
                        onBlur={() => setToZero(values, "tare_weight", setFieldValue)}
                      />
                      <ErrorMessage name="tare_weight" component="div" className="formik-error" />
                    </div>
                    <div className="col">
                      <label className="formik-label" htmlFor="tare_weight">
                        Net Weight
                      </label>
                      <Field
                        name="net_weight"
                        label="net_weight"
                        type="number"
                        disabled={true}
                        value={calculateNetWeight(values.gross_weight, values.tare_weight)}
                      />
                      <ErrorMessage name="net_weight" component="div" className="formik-error" />
                      {calculateNetWeightTons(values.gross_weight, values.tare_weight)}
                    </div>
                  </div>
                  <br />
                  <div className="row text-center">
                    <div className="col text-center">
                      <div
                        className="btn btn-primary"
                        style={{ marginTop: "0px" }}
                        onClick={() => calculateScaleMultiplier(values.dump_location_id, setFieldValue, "gross_weight")}>
                        Read full weight
                      </div>
                      {needDumpLocation && <div>Please select a dump location before reading from scale</div>}
                    </div>

                    <div className="col text-center">
                      <div
                        className="btn btn-primary"
                        style={{ marginTop: "0px" }}
                        onClick={() => calculateScaleMultiplier(values.dump_location_id, setFieldValue, "tare_weight")}>
                        Read empty weight
                      </div>
                      {needDumpLocation && <div>Please select a dump location before reading from scale</div>}
                    </div>

                    <div className="col"></div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col">
                  <h5 id="load-size-group">Load Size</h5>
                  <div role="group" aria-labelledby="load-size-group">
                    <div className="row">
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_full" value="100" />
                          <label className="formik-label" htmlFor="load_size_full">
                            Full
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_other" value="other" />
                          <label className="formik-label" htmlFor="load_size_other">
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_75" value="75" />
                          <label className="formik-label" htmlFor="load_size_75">
                            3/4
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" id="load_size_50" value="50" />
                          <label className="formik-label" htmlFor="load_size_50">
                            1/2
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="check-as-button">
                          <Field type="radio" name="load_size" value="25" id="load_size_25" />
                          <label className="formik-label" htmlFor="load_size_25">
                            1/4
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MonitorLoadSizeChange setShowGrossAndTare={setShowGrossAndTare} />
            </div>
            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
